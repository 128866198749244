<template>
	<div v-if="$api.texts['order-done-online']">
		<Order>
			<div v-html="$api.texts['order-done-online'].raw"></div>
			<p v-if="$storage.order">
				Pokud se tak nestane, můžete na ni přistoupit přes <a :href="$storage.order.payment_url">tento odkaz</a>.
			</p>
		</Order>
	</div>
</template>

<script>
	import Order from "../Order";
  import gAds from "../../../google";

	export default {
		name: "OrderOnlinePayment",
		metaInfo: {
			title: "Děkujeme za objednávku",
			meta: [{
				name: "robots",
				content: "noindex"
			}]
		},
		components: {Order},
		data() {
			return {
				timeout: 5	// timeout in seconds before redirecting to payment gate
			};
		},
		mounted() {
			if (!this.$storage.order) {
        this.$router.push({
          name: "Home"
        });
        return;
      }

      setTimeout(() => {
        document.location = this.$storage.order.payment_url;
      }, this.timeout * 1000);

			gAds.conversion(this.$storage.order.total);
		}
	}
</script>